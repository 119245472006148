var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"proto-group-table-component"},[(!!_vm.option.noticeTitle)?_c('el-alert',{staticStyle:{"margin":"0px 0 20px"},attrs:{"title":_vm.option.noticeTitle,"type":"warning","show-icon":""}}):_vm._e(),_c('CheckAllData4',{ref:"checkAllData4",attrs:{"showDom":false,"isPageChange":_vm.isPageChange,"tableData":_vm.domData,"page":_vm.page,"size":_vm.size,"total":_vm.total,"selectionData":_vm.selectedData},on:{"update:isPageChange":function($event){_vm.isPageChange=$event},"update:is-page-change":function($event){_vm.isPageChange=$event},"update:selectionData":function($event){_vm.selectedData=$event},"update:selection-data":function($event){_vm.selectedData=$event}}}),_c('div',{staticClass:"tabel-wrapper"},[_c('CommonTable',{ref:"table",attrs:{"height":"350px","tableLoading":_vm.tableLoading,"cols":_vm.cols,"selectable":_vm.selectable,"infoData":_vm.domData},on:{"selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"productCategoryNameSlot",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.productCategoryName(row))+" ")]}},{key:"styleListSlot",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.styleNameList(row))+" ")]}},{key:"sizeListSlot",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.sizeNameList(row))+" ")]}},{key:"mergeSlot",fn:function(ref){
var row = ref.row;
return [_c('defaultImg',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":_vm.figure(row)}})]}}])}),_c('PaginationBar',{attrs:{"fSize":_vm.fSize,"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.resetRefreshTableEventFun}})],1),_c('el-divider'),_c('div',{staticClass:"base-form_wrapper"},[_c('BaseForm',{ref:"form",staticStyle:{"width":"500px"},attrs:{"label-width":"auto","cols":_vm.option.baseFormField,"form":_vm.form}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }