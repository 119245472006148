<template>
  <div class="editPrototype">
    <color-text-btn @click="goInfoPage('edit')"> 编辑 </color-text-btn>
  </div>
</template>

<script>
import ColorTextBtn from "@/components/base/colorTextBtn";
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: { ColorTextBtn },
  data () {
    return { }
  },
  computed: { },
  methods: {
    goInfoPage(type) {
      this.$store.commit('SET_BUS', {
        basicsEdit: this.data
      })
      this.$router.push({
        path: `/product/maintain/basicsEdit?type=${type}&status=${this.data.status}`,
        query: {
          editText: 1
        }
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.editPrototype {
  display: inline-block;
}
</style>