<template>
  <section>
    <BaseProductPage
      :cols="cols"
      :formField="formField"
      :resetUrl="resetUrl"
      :isSearch="isSearch"
      :showProductStatus="showProductStatus"
      :viewPriceBtn="viewPriceBtn"
      :referSizeSpanCol="['参考尺码', '尺码']"
      :hasCheckAllData4="hasCheckAllData4"
      :resetMergeData="resetMergeData"
      v-if="checkPermission(['externaladmin:product:shelves'])"
    >
      <template #top="{ sup_this }">
        <headerBar
          :radioGroupBtn="headerBarCol"
          style="margin-bottom: 20px"
          :query="sup_this.query"
          :init="sup_this.tabInit"
          @change="barChange($event, sup_this)"
        ></headerBar>
      </template>
      <template #tips>
        <el-alert style="margin: 0px 0 20px" title="价格未设置不能上架" type="warning" show-icon> </el-alert>
      </template>
      <template v-slot:batchOperation="{ sup_this }">
        <BatchOperation class="batch-operation-wrapper" style="margin-top: 0">
          <div class="g-left-operation">
            <BulkShelves
              v-if="checkPermission(['externaladmin:product:shelves:batchUnShelves']) && barIndex === 1"
              :sup_this="sup_this"
              :isOff="isOff"
            />
            <!-- 生成原型分组 -->
            <CreateAssignGroup
              v-if="hasCreateAssignGroup && barIndex === 1"
              title="生成原型分组"
              style="margin-left: 3px"
              :isSelectedMode="true"
              :sup_this="sup_this"
              :hasDoSubmit="hasDoSubmit"
              @successHandler="sup_this.clearAllSelectedData"
            />
            <set-category
              v-if="barIndex === 0 || barIndex === 1"
              :type="['set', 'edit'][barIndex]"
              :is-batch="true"
              :sup_this="sup_this"
              :data="sup_this.selectedData"
              @success="sup_this.init"
            ></set-category>

            <SetProductLabel
              v-if="barIndex === 1 && hasSetProductLabel"
              :sup_this="sup_this"
              @success="sup_this.init"
            />
            <addGroup v-if="showAddBtn" :sup_this="sup_this" :data="sup_this.selectedData" @success="sup_this.init" />
          </div>
        </BatchOperation>
      </template>
      <template v-slot="{ sup_this }">
        <el-table-column v-if="barIndex !== 2" fixed="right" align="center" label="操作" width="300px">
          <template #default="{ row }">
            <putUpShelves
              v-if="checkPermission(['externaladmin:product:shelves:shelves']) && barIndex === 1"
              :sup_this="sup_this"
              :data="row"
            />
            <OffShelves
              v-if="checkPermission(['externaladmin:product:shelves:unShelves']) && barIndex === 1"
              :sup_this="sup_this"
              :data="row"
              :isOff="isOff"
            />
            <setCategory :data="[row]" :init="sup_this.init" v-if="barIndex === 0 && hsaSetCategory"></setCategory>
            <EditPrototype :data="row" v-if="barIndex === 0 && false"></EditPrototype>
          </template>
        </el-table-column>
      </template>
    </BaseProductPage>

    <assignDistributor v-if="canAssign" ref="assignDistributor"></assignDistributor>
  </section>
</template>

<script>
import BaseProductPage from '../baseProductPage'
import OffShelves from '../module/offShelves'
import putUpShelves from '../module/putUpShelves.vue'
import BulkShelves from '../module/bulkShelves'
import CreateAssignGroup from '@/views/components/createAssignGroup'
import EditPrototype from '@/views/product/shelves/module/editPrototype'
import assignDistributorMixin from '../module/assignDistributor/mixin'
import { shelvesField as formField } from '../field'
import { shelvesCols as cols } from '../cols'
import { SHELVES } from '@/utils/constant'
import headerBar from './module/headerBar'
import { headerBarCol } from '@/views/product/shelves/module/col'
import setCategory from '@/views/product/shelves/module/setCategory'
import SetProductLabel from '@/views/product/shelves/module/setProductLabel'
import addGroup from './module/addGroup'

export default {
  components: {
    BaseProductPage,
    OffShelves,
    putUpShelves,
    BulkShelves,
    CreateAssignGroup,
    headerBar,
    setCategory,
    SetProductLabel,
    EditPrototype,
    addGroup
  },
  mixins: [assignDistributorMixin],
  data() {
    return {
      cols,
      SHELVES,
      formField,
      isOff: true,
      headerBarCol,
      resetUrl: '/externaladmin/prototypeService/productPrototype/list',
      isSearch: this.checkPermission(['externaladmin:product:shelves:search']),
      hsaSetCategory: this.checkPermission('externaladmin:product:shelves:batchUpdateCategory'),
      viewPriceBtn: this.checkPermission(['externaladmin:product:shelves:viewPrice']),
      canAssign: this.checkPermission(['externaladmin:product:shelves:assign']),
      hasCheckAllData4: this.checkPermission(['externaladmin:product:shelves:checkAll']),
      hasCreateAssignGroup: this.checkPermission(['externaladmin:product:shelves:createAssignProtoGroup']),
      hasDoSubmit: this.checkPermission(['externaladmin:product:shelves:createAssignProtoGroup:add']),
      hasSetProductLabel: this.checkPermission(['externaladmin:product:shelves:setProductLabel']),
      barIndex: 0,
      resetMergeData: {
        isReturnReferSizeData: 1
      }
    }
  },

  computed: {
    showProductStatus({ barIndex }) {
      return [true, true, false][barIndex]
    },

    showAddBtn({ barIndex, checkPermission }) {
      return barIndex === 1 && checkPermission(['externaladmin:product:shelves:addGroup'])
    }
  },

  methods: {
    async barChange(i, ref) {
      ref.checkAllData4Change()
      this.barIndex = i
    }
  }
}
</script>

<style></style>
