<template>
  <DialogTable
    :checkAll="true"
    v-bind="$attrs"
    v-on="$listeners"
    customClass="unique-add-group-wrapper"
    top="5vh"
    width="1200"
    btnType="info"
    btnText="批量添加到分组"
    title="添加到原型分组"
    :api="getList"
    :option="option"
    :sup_this="sup_this"
    :beforeOpen="beforeOpen"
    @submit="onSubmit"
  >
  </DialogTable>
</template>

<script>
import productApi from '@/api/productApi'
import { map } from 'lodash'
import baseTableMixin from '@/components/base/baseTable/mixins/baseTableMixin'

export default {
  mixins: [baseTableMixin({})],

  props: {
    data: Array,
    type: String
  },

  data() {
    this.getList = productApi.list
    return {
      sup_this: this
    }
  },

  computed: {
    option() {
      return {
        menu: false,
        border: true,
        isOnePage: false,
        selection: true,
        pageSizes: [10, 20, 50, 100, 200],
        column: [
          {
            label: '分组名称',
            prop: 'name'
          },
          {
            label: '分组原型数',
            prop: 'count'
          },
          {
            label: '是否默认',
            prop: 'isDefault',
            dicData: [
              {
                label: '是',
                value: 1
              },
              {
                label: '否',
                value: 0
              }
            ]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            width: 250
          }
        ]
      }
    }
  },

  methods: {
    beforeOpen() {
      if (!this.data.length) {
        this.$message.warning('请先选择需要的数据，再进行此操作')
        return false
      }
      return true
    },

    async onSubmit(form, done) {
      const selectedData = await this.getSelectionData()
      if (!selectedData) {
        this.$message.warning('请先选择数据')
        return done(false)
      }

      const prototypeIdList = map(this.data, 'id')
      const idList = map(selectedData, 'id')
      done(
        productApi.update({
          idList,
          prototypeIdList
        })
      )
    }
  }
}
</script>

<style lang="scss">
.unique-add-group-wrapper {
  .el-table {
    max-height: 592px;
    overflow: auto;
  }
}
</style>
