<template>
  <div class="line inline-block">
    <el-button :loading="loading" type="info" size="mini" class="filter-item" @click="to">
      {{ option.btnTxt }}
    </el-button>
    <AssignGroupDialog
      ref="form"
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :isSelectedMode="isSelectedMode"
      :selectedData="selectedData"
      :sup_this="sup_this"
      :title="option.title"
      :type="type"
      :hasDoSubmit="hasDoSubmit"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import AssignGroupDialog from './module/assignGroupDialog'
import { commonEheaderMixin } from '@/mixins'
import cloneDeep from 'lodash/cloneDeep'
import { getOption } from './const'

export default {
  mixins: [commonEheaderMixin],
  
  components: { AssignGroupDialog },

  props: {
    type: {
      type: String,
      default: 'default'
    },

    //是否选择模式
    isSelectedMode: Boolean,
    hasDoSubmit: Boolean
  },

  data() {
    return {
      selectedData: [],
      loading: false
    }
  },

  computed: {
    option({ type }) {
      return getOption(type)
    }
  },

  methods: {
    async checkSelectedData() {
      const { isSelectedMode } = this
      if (!isSelectedMode) return true
      try {
        this.loading = true
        const [err, data] = await this.sup_this.getAllSelectedDataData()
        this.selectedData = cloneDeep(data)
        return !!this.selectedData.length
      } catch {
        return false
      } finally {
        this.loading = false
      }
    },

    async to() {
      if (!(await this.checkSelectedData())) return
      this.showDialog()
    }
  }
}
</script>
