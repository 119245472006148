import { OFF_SHELVES } from '@/utils/constant'

export const headerBarCol = [
  {
    label: '未分类',
    prop: 'notClassified',
    value: {
      categoryType: 1
    }
  },
  {
    label: '已分类',
    prop: 'classified',
    value: {
      categoryType: 2
    }
  },
  {
    label: '已下架',
    prop: 'offShelves',
    value: {
      categoryType: 3
    }
  }
]
export const changeCategoryTableOption =  {
  btn: false,
  editBtn: false,
  delBtn: false,
  column: [
    {
      label: '产品名称',
      prop: 'chineseName'
    },
    {
      label: '产品展示图',
      prop: 'image',
      width: '300px'
    },
    {
      label: '当前分类',
      prop: 'productCategory.name'
    }
  ]
}
