var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.checkPermission(['externaladmin:product:shelves']))?_c('BaseProductPage',{attrs:{"cols":_vm.cols,"formField":_vm.formField,"resetUrl":_vm.resetUrl,"isSearch":_vm.isSearch,"showProductStatus":_vm.showProductStatus,"viewPriceBtn":_vm.viewPriceBtn,"referSizeSpanCol":['参考尺码', '尺码'],"hasCheckAllData4":_vm.hasCheckAllData4,"resetMergeData":_vm.resetMergeData},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var sup_this = ref.sup_this;
return [_c('headerBar',{staticStyle:{"margin-bottom":"20px"},attrs:{"radioGroupBtn":_vm.headerBarCol,"query":sup_this.query,"init":sup_this.tabInit},on:{"change":function($event){return _vm.barChange($event, sup_this)}}})]}},{key:"tips",fn:function(){return [_c('el-alert',{staticStyle:{"margin":"0px 0 20px"},attrs:{"title":"价格未设置不能上架","type":"warning","show-icon":""}})]},proxy:true},{key:"batchOperation",fn:function(ref){
var sup_this = ref.sup_this;
return [_c('BatchOperation',{staticClass:"batch-operation-wrapper",staticStyle:{"margin-top":"0"}},[_c('div',{staticClass:"g-left-operation"},[(_vm.checkPermission(['externaladmin:product:shelves:batchUnShelves']) && _vm.barIndex === 1)?_c('BulkShelves',{attrs:{"sup_this":sup_this,"isOff":_vm.isOff}}):_vm._e(),(_vm.hasCreateAssignGroup && _vm.barIndex === 1)?_c('CreateAssignGroup',{staticStyle:{"margin-left":"3px"},attrs:{"title":"生成原型分组","isSelectedMode":true,"sup_this":sup_this,"hasDoSubmit":_vm.hasDoSubmit},on:{"successHandler":sup_this.clearAllSelectedData}}):_vm._e(),(_vm.barIndex === 0 || _vm.barIndex === 1)?_c('set-category',{attrs:{"type":['set', 'edit'][_vm.barIndex],"is-batch":true,"sup_this":sup_this,"data":sup_this.selectedData},on:{"success":sup_this.init}}):_vm._e(),(_vm.barIndex === 1 && _vm.hasSetProductLabel)?_c('SetProductLabel',{attrs:{"sup_this":sup_this},on:{"success":sup_this.init}}):_vm._e(),(_vm.showAddBtn)?_c('addGroup',{attrs:{"sup_this":sup_this,"data":sup_this.selectedData},on:{"success":sup_this.init}}):_vm._e()],1)])]}},{key:"default",fn:function(ref){
var sup_this = ref.sup_this;
return [(_vm.barIndex !== 2)?_c('el-table-column',{attrs:{"fixed":"right","align":"center","label":"操作","width":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.checkPermission(['externaladmin:product:shelves:shelves']) && _vm.barIndex === 1)?_c('putUpShelves',{attrs:{"sup_this":sup_this,"data":row}}):_vm._e(),(_vm.checkPermission(['externaladmin:product:shelves:unShelves']) && _vm.barIndex === 1)?_c('OffShelves',{attrs:{"sup_this":sup_this,"data":row,"isOff":_vm.isOff}}):_vm._e(),(_vm.barIndex === 0 && _vm.hsaSetCategory)?_c('setCategory',{attrs:{"data":[row],"init":sup_this.init}}):_vm._e(),(_vm.barIndex === 0 && false)?_c('EditPrototype',{attrs:{"data":row}}):_vm._e()]}}],null,true)}):_vm._e()]}}],null,false,853202346)}):_vm._e(),(_vm.canAssign)?_c('assignDistributor',{ref:"assignDistributor"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }