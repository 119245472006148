<template>
  <div class="line setCategory">
    <LoadingBtn @click="showDialog" type="info" size="mini" v-if="isBatch">{{ text }}</LoadingBtn>
    <color-text-btn @click="showDialog" v-else>{{ text }}</color-text-btn>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="70%" append-to-body v-loading="loading">
      <proto-type-tree-select
        class="mb20"
        size="mini"
        style="width: 300px"
        placeholder="请选择原型分类"
        :selectedValue.sync="category"
        :maxHeight="150"
      >
      </proto-type-tree-select>
      <base-table :option="option" :data="selectData" class="base-table-height">
        <template #image="{ row }">
          <el-image style="width: 50px; height: 50px" :src="figure(row)" fit="contain"></el-image>
        </template>
        <template #menu="{ row }">
          <color-text-btn @click="choiceChange(row)">{{ submitText(row) }}</color-text-btn>
        </template>
      </base-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ColorTextBtn from '@/components/base/colorTextBtn'
import baseTable from '@/components/base/baseTable'
import ProtoTypeTreeSelect from '@/components/protoTypeTreeSelect'
import { changeCategoryTableOption as option } from './col'
import { bulkChangeProd } from '@/api/product/productApi'
import { cloneDeep } from 'lodash'

export default {
  props: {
    type: {
      type: String,
      default: 'set'
    },
    isBatch: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array | Object,
      default: []
    },
    init: Function,
    sup_this: Object
  },
  components: { baseTable, ColorTextBtn, ProtoTypeTreeSelect },
  data() {
    return {
      dialogVisible: false,
      option,
      category: '',
      loading: false,
      selectData: []
    }
  },
  computed: {
    text() {
      const tmpArr = []
      if (this.isBatch) tmpArr.push('批量')
      tmpArr.push(this.type === 'edit' ? '修改' : '设置')
      tmpArr.push('分类')
      return tmpArr.join('')
    },

    submitText() {
      return (row) => {
        return row.selectOption ? '取消选择' : '选择'
      }
    },

    figure() {
      return (row) => this.aLiCompressPicByUrl($GET(row, 'styleList[0].styleDisplayImageList[0].displayImagePath', ''), 80)
    }
  },
  methods: {
    async submit() {
      if (!this.category) {
        return this.$message.warning('请先选择分类')
      }
      this.loading = true
      try {
        let submitIdList = []
        this.selectData.map((item) => {
          if (item.selectOption === true) {
            submitIdList.push(item.id)
          }
        })
        if (!submitIdList.length) {
          this.$message.warning('请至少选择一个产品')
          throw ''
        }

        let query = {
          productCategoryId: this.category,
          productPrototypeIdList: submitIdList
        }
        let res = await bulkChangeProd(query)
        if ($SUC(res)) {
          this.$emit('success')
          this.$message.success('操作成功')
          this.init?.()
          this.dialogVisible = false
        } else {
          this.$message.error('操作失败')
        }
      } catch {
      } finally {
        this.loading = false
      }
    },

    async getSelectData() {
      let selectData = null
      if (this.isBatch) {
        [, selectData] = await this.sup_this.getAllSelectedDataData()
      } else {
        selectData = this.data
      }
      this.selectData = this.formatSelectData(selectData)
    },

    formatSelectData(data) {
      // 选项去重，因为点击全选可能有重复
      data = cloneDeep(data)
      let idList = []
      let newData = []
      data.map((item) => {
        if (!idList.includes(item.id)) {
          idList.push(item.id)
          item.selectOption = true
          newData.push(item)
        }
      })
      return newData
    },

    async showDialog() {
      await this.getSelectData()
      this.dialogVisible = true
    },

    choiceChange(row) {
      row.selectOption = !row.selectOption
    }
  }
}
</script>

<style lang="scss" scoped>
.setCategory {
  display: inline-block;
}

::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ccc !important;

    .el-dialog__title {
      font-size: 16px;
    }
  }

  .base-table-height {
    height: 400px !important;
  }
}
</style>
