import { createProtoGroupListCols, createPublicImageGroupListCols } from './cols'
import { protoBaseFormField, pictureBaseFormField } from './field'
import { getAllData, getOpenList } from '@/views/product/maintain/basicsMange/module/api'
import { createProtoGroup } from '@/api/product/protoGroupApi'
import { createPictureGroup } from '@/api/image/pictureGroupApi'
import { SHELVES, PUBLIC_PIC_DISABLE, REQUEST_ALL_DATA } from '@/utils/constant'

export const optionList = {
  picture: {
    title: '生成图片分组',
    btnTxt: '生成图片分组',
    noticeTitle: '未上架的图片无法分配',
    cols: createPublicImageGroupListCols,
    resetUrl: '/externaladmin/productService/image/openList',
    baseFormField: pictureBaseFormField,
    async getDisAbledList() {
      try {
        const { detail } = await getOpenList({
          disable: PUBLIC_PIC_DISABLE,
          ...REQUEST_ALL_DATA
        })
        return detail || []
      } catch {
        return []
      }
    },
    addSubmitFuncApi(data) {
      const { name } = this.form
      return createPictureGroup({
        name,
        imageIdList: data
      })
    }
  },

  default: {
    title: '生成原型分组',
    btnTxt: '生成原型分组',
    noticeTitle: '未上架的原型无法分配',
    cols: createProtoGroupListCols,
    resetUrl: '/externaladmin/prototypeService/productPrototype/list',
    baseFormField: protoBaseFormField,
    async getDisAbledList() {
      try {
        const { detail } = await getAllData({
          ...REQUEST_ALL_DATA
        })
        return (detail || []).filter(({ status }) => status != SHELVES)
      } catch {
        return []
      }
    },
    addSubmitFuncApi(data) {
      const { name } = this.form
      return createProtoGroup({
        name,
        prototypeIdList: data
      })
    }
  }
}

export function getOption(type) {
  return optionList[type]
}
