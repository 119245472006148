import { requireFun } from '@/utils'

//创建原型分组列表
export const createProtoGroupListField = [
  {
    label: '原型名称',
    prop: 'name',
    name: 'name',
    colNum: 8,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'structName',
    name: 'struct_name',
    colNum: 8,
    placeholder: '请输入款式'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]

export const protoBaseFormField = [
  {
    label: '原型分组名称',
    prop: 'name',
    name: 'name',
    colNum: 24,
    placeholder: '输入原型分组名称',
    rule: [
      {
        required: true,
        message: requireFun('原型分组名称')
      }
    ]
  }
]

export const pictureBaseFormField = [
  {
    label: '图片分组名称',
    prop: 'name',
    name: 'name',
    colNum: 24,
    placeholder: '输入图片分组名称',
    rule: [
      {
        required: true,
        message: requireFun('图片分组名称')
      }
    ]
  }
]
