<template>
  <dialogTable
    :option="option"
    :beforeOpen="onBeforeOpen"
    :data="data"
    @close="onClose"
    @submit="onSubmit"
    v-bind="all$Attrs"
    v-on="$listeners"
  >
    <template v-for="item in checkedColList" #[`${item.prop}Header`]>
      <el-checkbox
        v-model="item.checked"
        :indeterminate="item.indeterminate"
        @change="checkedChangeByData($event, data, item)"
      >
        全选
      </el-checkbox>
    </template>

    <template v-for="item in checkedColList" #[item.prop]="{row}">
      <el-checkbox
        v-model="row[item.checkedProp]"
        @change="checkedChangeByData($event, row, item)"
      />
      <component :is="item.tagComponent"/>
    </template>

    <template #image="{ row }">
      <el-image style="height: 50px" :src="figure(row)" fit="contain"></el-image>
    </template>
  </dialogTable>
</template>

<script>
import { tableOption } from './const'
import { isArray } from 'lodash'
import { bestsellerNewArriveBatchUpdate } from '@/api/product/productApi'

const DEFAULT_OPTION = {
  btnType: 'info',
  width: '70%',
  height: 600,
  btnText: '新品标签设置',
  title: '标签设置'
}
export default {
  props: {
    sup_this: {
      required: true
    }
  },

  data() {
    return {
      data: [],
      checkedColList: [
        {
          value: 'newArrive',
          prop: 'isNewArrive',
          checkedProp: 'isNewArriveChecked',
          checked: false,
          indeterminate: false,
          tagComponent: {
            render(createElement) {
              return createElement('span', {
                class: ['new-product-tag', 'ml10']
              }, '新')
            }
          },
          init: () => {
            const fItem = this.checkedColList.find(item => item.value == 'newArrive')
            const dataOfSelected = this.data.filter(row => $GET(row, 'productPrototypePlatform.isNewArrive', false))
            const dataOfNotSelected = this.data.filter(row => !$GET(row, 'productPrototypePlatform.isNewArrive', false))
            this.checkedChangeByData(true, dataOfSelected, fItem)
            this.checkedChangeByData(false, dataOfNotSelected, fItem)
          }
        }
      ],
      option: tableOption
    }
  },

  computed: {
    all$Attrs({ $attrs }) {
      return Object.assign({}, DEFAULT_OPTION, $attrs)
    },

    figure() {
      return (row) => $GET(row, 'styleList[0].styleDisplayImageList[0].displayImagePath', '')
    }
  },

  methods: {
    async onSubmit(form, done) {
      const params = getParams.call(this)
      const res = await awaitResolveDetailLoading(bestsellerNewArriveBatchUpdate(params))
      if(res) {
        this.$emit('success')
      }
      done(!!res)

      function getParams() {
        const params = {
          list: []
        }
        this.data.map(item => {
          const { isNewArriveChecked, id } = item
          const productPrototypePlatformId = $GET(item, 'productPrototypePlatform.id', null)
          params.list.push({
            id: productPrototypePlatformId,
            isNewArrive: isNewArriveChecked ? 1 : 0
          })
        })
        return params
      }
    },

    async onBeforeOpen() {
      const isPass = await this.getSelectData()
      if (!isPass) return
      this.init()
      return true
    },

    onClose() {
      Object.assign(this, this.$options.data.call(this))
    },

    init() {
      this.checkedColList.map(item => {
        item.init()
      })
    },

    async getSelectData() {
      const [err, data] = await this.sup_this.getAllSelectedDataData()
      if (err) return false
      this.data = data
      return true
    },

    checkedChangeByData(checked, data, item) {
      const { checkedProp } = item
      const allData = this.data
      if (!isArray(data)) data = [data]
      data.forEach(row => {
        checkedOfRowChange.call(this, row, checkedProp, checked)
      })
      const allSelected = allData.every(row => row[checkedProp])
      const someSelected = allData.some(row => row[checkedProp])
      if (allSelected) {
        item.checked = true
        item.indeterminate = false
        return
      }

      if (someSelected) {
        item.checked = false
        item.indeterminate = true
        return
      }

      item.checked = false
      item.indeterminate = false

      function checkedOfRowChange(row, checkedProp, checked) {
        this.$set(row, checkedProp, checked)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.new-product-tag {
  background: $green;
  color: #fff;
  padding: 2px;
  border-radius: 2px;
}
</style>
