import { apiFactory } from '@/utils/constant'

const productApi = {
  list: '/productService/prototypeGroup/list',
  update: '/productService/prototypeGroup/addPrototypeUpdate'
}

for (const key in productApi) {
  productApi[key] = apiFactory(productApi[key])
}

export default productApi
