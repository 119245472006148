export const tableOption = {
  menu: false,
  column: [
    {
      label: '产品名称',
      prop: 'chineseName'
    },
    {
      label: '产品展示图',
      prop: 'image'
    },
    {
      label: '新品标签',
      prop: 'isNewArrive'
    }
  ]
}
