<template>
  <div uiid="zd-tabs" class="my-header-list-box" ref="headerBox">
    <div class="my-header-list" ref="headerBar">
      <div v-for="(item, index) in radioGroupBtn" :key="index">
        <div
          class="list-label"
          v-if="item.root !== false"
          :class="{ labelBorder: index === currentIndex }"
          @click="clickItem(item, index)"
        >
          <span :id="`tab-${item.prop || index}`">{{ item.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    radioGroupBtn: {
      type: Array,
      default: () => {
        return []
      }
    },
    init: {
      type: Function
    },
    query: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  computed: {},
  created() {
    Object.assign(this.query, this.radioGroupBtn[0].value)
  },
  methods: {
    // 移动事件
    drag(e) {
      // 获取点击时的坐标
      let pos = this.getPos(e)
      // 获得最开始点击的x坐标
      let startX = pos.x
      const style = this.$refs['headerBar'].style
      let marginLeft = parseInt(style['margin-left'])
      if (isNaN(marginLeft)) {
        marginLeft = 0
      }
      // 设置移动的距离
      let boxWidth = this.$refs['headerBox'].clientWidth
      if (this.radioGroupBtn.length * 150 > boxWidth) {
        document.onmousemove = (e) => {
          let pos = this.getPos(e)
          let nowX = pos.x
          let moveX = nowX - startX
          style['margin-left'] = moveX + marginLeft + 'px'
          if (moveX + marginLeft >= 0) {
            style['margin-left'] = '0px'
            //   document.onmousemove = null
          }
          let minleft = boxWidth - this.radioGroupBtn.length * 150
          if (moveX + marginLeft <= minleft) {
            style['margin-left'] = minleft + 'px'
          }
        }
      }
      document.onmouseup = function () {
        /*鼠标放开清除事件*/
        document.onmousemove = null
        document.onmouseup = null
      }
    },
    // 获取鼠标坐标
    getPos(ev) {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft
      return { x: ev.clientX + scrollLeft, y: ev.clientY + scrollTop }
    },
    clickItem(item, index) {
      this.currentIndex = index
      this.$emit('change', index)
      this.query = Object.assign(this.query, item.value)
      this.init()
    }
  }
}
</script>

<style scoped lang="scss">
.my-header-list-box {
  width: 100%;
  overflow: hidden;
  .my-header-list {
    display: flex;
    flex-wrap: wrap;
    .list-label {
      font-size: 14px;
      height: 40px;
      width: 150px;
      text-align: center;
      line-height: 40px;
      flex-grow: 0;
      flex-shrink: 0;
      color: #333;
      border: 1px solid #dcdee0;
      background-color: #f7f8fa;
      cursor: pointer;
      user-select: none;
    }
    .list-label:first-child {
      border-radius: 4px 0 0 4px;
    }
    .list-label:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}
.labelBorder {
  border-bottom: 3px solid $color-primary !important;
  color: $color-primary;
}
</style>
