<template>
  <div class="proto-group-table-component">
    <el-alert
      style="margin: 0px 0 20px"
      v-if="!!option.noticeTitle"
      :title="option.noticeTitle"
      type="warning"
      show-icon
    >
    </el-alert>

    <CheckAllData4
      ref="checkAllData4"
      :showDom="false"
      :isPageChange.sync="isPageChange"
      :tableData="domData"
      :page="page"
      :size="size"
      :total="total"
      :selectionData.sync="selectedData"
    />

    <div class="tabel-wrapper">
      <CommonTable
        ref="table"
        height="350px"
        :tableLoading="tableLoading"
        :cols="cols"
        :selectable="selectable"
        :infoData="domData"
        @selection-change="handleSelectionChange"
      >
        <template #productCategoryNameSlot="{ row }">
          {{ productCategoryName(row) }}
        </template>

        <template #styleListSlot="{ row }">
          {{ styleNameList(row) }}
        </template>

        <template #sizeListSlot="{ row }">
          {{ sizeNameList(row) }}
        </template>

        <!-- 已上架原型  展示图/原型名称/海关编号列-->
        <template #mergeSlot="{ row }">
          <defaultImg :src="figure(row)" style="width: 60px; height: 60px"></defaultImg>
        </template>
      </CommonTable>
      <PaginationBar
        :fSize="fSize"
        :page="page"
        :size="size"
        :total="total"
        @refreshTableEventFun="resetRefreshTableEventFun"
      />
    </div>
    <el-divider></el-divider>
    <div class="base-form_wrapper">
      <BaseForm style="width: 500px" ref="form" label-width="auto" :cols="option.baseFormField" :form="form">
      </BaseForm>
    </div>
  </div>
</template>

<script>
import CheckAllData4 from '@/views/components/checkAllData4'

import { initDataMixin } from '@/mixins'
import checkAll4Mixin from '@/views/components/checkAllData4/checkAll4Mixin'
import { get } from 'lodash'
import { getOption } from '../const'

export default {
  components: {
    CheckAllData4
  },

  mixins: [initDataMixin, checkAll4Mixin()],

  props: {
    cols: {
      type: Array,
      required: true
    },

    type: {
      type: String,
      default: 'default'
    },

    form: {
      type: Object,
      default: () => ({})
    },

    resetData: {
      type: Array,
      default: () => []
    },

    disAbledList: {
      type: Array,
      default: () => []
    },

    //是否选择模式
    isSelectedMode: Boolean
  },

  data() {
    return {
      sup_this: this,
      size: 5,
      url: this.resetUrl,
      tableLoading: false,
      initBool: false, //外部是否传选择数据
      fSize: [5, 10, 15, 20]
    }
  },

  computed: {
    tableData({ isSelectedMode, data, resetData }) {
      return isSelectedMode ? resetData : data
    },

    option({ type }) {
      return getOption(type)
    },
    /**
     * @description: 将数据分页展示
     * @param {Array} tableData
     * @param {Object} tablePage
     * @param {*} pageSize
     * @return {Array} 当前页的数据，跟pageSize和tableData
     */
    domData({ isSelectedMode, tableData, page, size }) {
      if (!isSelectedMode) {
        return tableData
      }
      this.total = tableData.length //初始化数据总数
      return tableData.slice((page - 1) * size, page * size)
    },

    //图片分类
    productCategoryName() {
      return (row) => {
        try {
          return row.productCategory.name
        } catch {
          return ''
        }
      }
    },

    styleNameList() {
      return (row) => {
        try {
          return $GET(row, 'styleList', [])
            .map(({ name }) => name)
            .join('、')
        } catch {
          return ''
        }
      }
    },

    sizeNameList() {
      return (row) => {
        try {
          return $GET(row, 'sizeList', [])
            .map(({ sizeName }) => sizeName)
            .join('、')
        } catch {
          return ''
        }
      }
    },

    figure() {
      return (row) => {
        return aLiCompressPicByUrl(get(row, 'thumbnailPath'), 200)
      }
    }
  },

  mounted() {
    //选择模式，选择初始值默认初始调用一次 initCallBack
    if (this.isSelectedMode) {
      this.initCheckAllData4Status()
    }

    if (!this.isSelectedMode) {
      this.url = this.option.resetUrl
    }
  },

  methods: {
    selectable(row) {
      return !this.disAbledList.find((id) => row.id == id)
    },

    /**
     * @description: 将多选框状态重置为true
     * @param {*}
     * @return {*}
     */
    initCheckAllData4Status() {
      this.$nextTick(() => {
        const { checkAllData4 } = this.$refs
        if (!checkAllData4) return
        checkAllData4.checked = true
      })
    },

    async validate() {
      const { form } = this.$refs
      try {
        return await form.validate()
      } catch {
        return false
      }
    },

    resetRefreshTableEventFun({ page, size }) {
      const { isSelectedMode } = this
      if (!isSelectedMode) {
        return this.refreshTableEventFun({ page, size })
      }
      this.syncRefreshTable({ page, size })
    },

    syncRefreshTable({ page, size }) {
      this.isPageChange = true
      this.page = page
      this.size = size
      setTimeout(() => {
        this.initCallBack()
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-tag {
  margin: 5px;
}
</style>